import React from "react";
import classNames from "classnames";

import css from "./product-grid.module.scss";

interface Props {
  children: React.ReactNode;
  darkMode?: boolean;
  isLoading?: boolean;
}

export function ProductGrid({ children, darkMode, isLoading }: Props) {
  return (
    <div className={css.productGrid}>
      {children}
      {isLoading && <div className={classNames(css.loading, { [css.loading__dark]: darkMode })} />}
    </div>
  );
}
